<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Alejandro Orozco Marulanda                                    ###### -->
<!-- ###### @date: Diciembre 2024                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <!--Snackbar de elemento exitoso-->
    <v-snackbar v-model="snackbarValue" :color="snackbarColor ? 'success' : 'error'" timeout="2000">
      {{ snackbarMessage }}
    </v-snackbar>
    <!-- FILTROS -->
    <section class= "d-grid">
      <section class= "d-flex">
        <!--Año de la evaluación-->
        <v-text-field class="me-2 filtro" v-model="buscar.ano" label="Año evaluación" outlined dense
          hide-details color="primary" @input="filtrarItems()" type="number" :min="2000" :max="currentYear">
        </v-text-field>
        <!--filtro por estado eliminado-->
        <FilterCustomized
          v-model="buscar.eliminado"
          :items="listaBusqueda.estado" item-value="id" item-text="name" label="Estado" :style="{ maxWidth: '30%' }"
          @input="filtrarItems()"
        />
        <!-- Este botón abre un crear una evaluación -->
        <div>
          <v-tooltip color="success" :open-on-focus="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab small color="success" v-on="on" v-bind="attrs"
                @mousedown.prevent="dialogoGuardarItem = true">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Agregar</span>
          </v-tooltip>
        </div>
      </section>
    </section>

   <!-- Diálogo para guardar una Item -->
   <v-dialog v-model="dialogoGuardarItem" transition="dialog-bottom-transition" max-width="35rem" persistent>
    <v-card>
      <v-card-title class="encabezado">
        <span class="text-h6"> {{ tituloDialogo }} </span>
      </v-card-title>
      <!-- Formulario para registrar una Item -->
      <v-card-text class="pt-5">
        <v-form ref="itemForm">
          <div class="d-flex">
            <!--Año de la evaluación-->
            <v-text-field class="me-2 filtro" v-model="crearItem.ano" label="Año evaluación" outlined dense :rules="rules.required && rules.between"
              :hide-details="false" color="primary" type="number" :style="{ maxWidth: '40%' }" :disabled="botonDisabled" :readonly="botonDisabled"
              @input="generarDescripcion">
            </v-text-field>
            <!--Tipo de la evaluación-->
            <FilterCustomized
              v-model="crearItem.tipoEvaluacionValue" :disabled="botonDisabled" :readonly="botonDisabled" :rules="rules.required" :hide-details="false"
              :items="listaGuardado.tipoEvaluacionValue" item-value="id" item-text="name" label="Tipo de Evaluación" :style="{ maxWidth: '60%' }"
             @input="generarDescripcion"
            />
          </div>
          <v-text-field class="mr-2 filtro" v-model="crearItem.descripcion" label="Descripcion" outlined dense :disabled="true"
            :hide-details="false" color="primary" :rules="rules.required">
          </v-text-field>
        </v-form>
        <!-- Botones del formulario -->
        <div class="d-flex justify-end">
          <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
          <v-btn :disabled="!formValid" depressed :color="!formValid ? 'grey' : 'success'"
            @click="guardarItem()">Guardar
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
   </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ estado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="guardarItem()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Tabla de items -->
    <v-data-table class="elevation mt-4" :items="tabla.items" :loading="tabla.loading" fixed-header :headers="tabla.headers"
      :footer-props="tabla.footerProps" :items-per-page="tabla.itemsPerPage" :page="tabla.page"
      @update:items-per-page="(i) => tabla.itemsPerPage = i" @update:page="(p) => tabla.page = p"
      :server-items-length="tabla.totalPage" height="64vh" @mouseenter="isMenuOpen = false">

      <template v-slot:[`item.estado`]="{ item }">
        <v-icon :color="item.eliminado === false ? 'success' : 'error'">
          {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
        </v-icon>
          {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <!-- <v-tooltip bottom color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon fab samll v-on="on" v-bind="attrs" class="ms-2 me-2" :disabled="item.eliminado"
              @click="abrirDialogoEditar(item)" :color="!item.eliminado ? 'orange' : 'grey'">
              <v-icon>border_color</v-icon>
            </v-btn>
          </template>
          <span>{{ !item.eliminado ? 'Editar' : 'Contrato inactivo' }}</span>
        </v-tooltip> -->

        <v-tooltip bottom
          :color="item.eliminado === false ? 'error' : 'success'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon medium class="ms-2 me-2" v-on="on" v-bind="attrs" @click="abrirDialogoEstado(item)">
              <v-icon medium :color="item.eliminado === false ? 'error' : 'success'">{{ item.eliminado === false ?
                'person_add_disabled' : 'how_to_reg'}}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
        </v-tooltip>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FilterCustomized from "@/components/human-talent/FilterCustomized.vue"
import { required } from "@/validators/validations";

export default {
  name:"TiposEvaluacion",
  components:{
    FilterCustomized,
  },
  data(){
    return{
      ruta:"msa-hr/api/lista-evaluacion",
      lastmodifiedby:"",
      userRoles : {},
      nombreId: 'idListaEvaluacion',
      currentYear: new Date().getFullYear(),
      rules: {
        required: [required],
        between:  [(value) => {
          return (value>=2022 && value<=this.currentYear) || `Valor debe estar entre 2022 y ${this.currentYear}`
        }]
      },
      snackbarValue: false,
      snackbarMessage: '',
      snackbarColor:false,
      dialogoCambiarEstado:false,
      dialogoGuardarItem:false,
      botonDisabled : false,
      tituloDialogo: "Agregar",
      nombreItem:"Evaluación",
      buscar:{
        ano:null,
        eliminado:null
      },
      listaBusqueda:{
        ano:[],
        estado:[
          {id:false,name:'ACTIVO'},
          {id:true,name:'INACTIVO'},
        ]
      },
      listaGuardado:{
        estado:[
          {id:false,name:'ACTIVO'},
          {id:true,name:'INACTIVO'},
        ],
        tipoEvaluacionValue:[
          {id:'A',name:'ANUAL'},
          {id:'P',name:'PARCIAL'},
          {id:'2',name:'PARCIAL (2)'},
        ]
      },
      crearItem:{},
      tabla: {
        loading: false,
        items: [],
        headers: [
          { text: "DESCRIPCIÓN", width: "30%", sortable: false, align: "center", value: "descripcion" },
          { text: "AÑO", width: "10%", sortable: false, align: "center", value: "ano" },
          { text: "TIPO", width: "20%", sortable: false, align: "center", value: "tipoEvaluacion" },
          { text: "ESTADO", width: "20%", sortable: false, align: "center", value: "estado" },
          { text: "ACCIONES", width: "20%", sortable: false, align: "center", value: "acciones" },
        ],
        footerProps: {
          'items-per-page-options': [10, 20, 30, 40],
          'items-per-page-text': 'Items por página:',
          'show-current-page': true,
          'show-first-last-page': true
        },
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
    }
  },
  computed:{
  ...mapState(["auth", "notify", "busy", "enterprise"]),
  formValid() {
      const { descripcion, tipoEvaluacionValue, ano} = this.crearItem;
      return descripcion && tipoEvaluacionValue && ano;
    },
  estado() {
    return !this.crearItem.eliminado
      ? `¿Desea activar ${this.nombreItem}?`
      : `¿Desea inactivar ${this.nombreItem}?`;
  },
  },
  watch: {
    // Escucha los cambios en la página actual de la tabla de items.
    // Si cambia, llama a la función para listar las items.
    "tabla.page": function () {
      this.listarItems();
    },
    // Escucha cambios en la cantidad de ítems por página en la tabla de items.
    // Si cambia, reinicia la página actual a 1 y lista los items.
    "tabla.itemsPerPage": function () {
      this.tabla.page = 1;
      this.listarItems();
    },
  },
  methods:{
    async filtrarItems(){
      this.tabla.page = 1;
      this.listarItems();
    },
    async listarItems() {       
      this.tabla.loading = true;
      try{
          const response = await this.$http
          .get(`${this.ruta}/listar`, {
            params: {
              idEmpresa: this.enterprise.code,
              ano: this.buscar.ano || null,
              eliminado:this.buscar.eliminado,
              page: this.tabla.page - 1,
              size: this.tabla.itemsPerPage,
            },
          })
      
        this.tabla.items = response.data.content;
        this.tabla.totalPage = response.data.totalElements;
        this.tabla.loading = false;

      }catch(error) {
        console.log(error);
        this.tabla.loading = false;
      }
    },
    //Configura el dialogo de edición de item con los datos del item a editar
    abrirDialogoEditar(item) {
      this.tituloDialogo = "Editar";
      this.crearItem = {
        ...item,
      };
      this.botonDisabled = true;
      this.dialogoGuardarItem = true;
    },
    abrirDialogoEstado(item) {
      this.crearItem = {
        ...item,
        eliminado: !item.eliminado,
      };
      this.dialogoCambiarEstado = true;
    },
    //Función para editar un Ítem
    guardarItem() {

      const item = {
        empresa: { idEmpresa: this.enterprise.code },
        descripcion: this.crearItem.descripcion,
        ano: this.crearItem.ano,
        eliminado: this.crearItem.eliminado,
        tipoEvaluacionValue: this.crearItem.tipoEvaluacionValue,
        lastmodifiedby: this.auth.username.toUpperCase(),
      }
      
      if(this.crearItem[this.nombreId]){
        item[this.nombreId]= this.crearItem[this.nombreId];
      }else{
        item.createdby = this.auth.username.toUpperCase();
      }
      
      this.$http.post(`${this.ruta}/guardar`, item)
        .then(() => {
          this.listarItems();
          this.dialogoCrearItem = false;
          this.clear();
          this.snackbarValue = true;
          this.snackbarMessage = 'Guardado Correctamente';
          this.snackbarColor = true;
        }).catch((error) => {
          console.log(error);
          this.snackbarValue = true;
          this.snackbarMessage = "El Tipo de Evaluación ya existe";
          this.snackbarColor = false;
        });
    },
    generarDescripcion(){
      this.crearItem.descripcion = '';
      if(this.crearItem.ano && this.crearItem.tipoEvaluacionValue){
        const tipoEvaluacion = this.listaGuardado.tipoEvaluacionValue.find(tipo => this.crearItem.tipoEvaluacionValue == tipo.id).name
        this.crearItem.descripcion = `Evaluación ${tipoEvaluacion} ${this.crearItem.ano}`;
      }
    },
    clear() {
      this.crearItem={};
      this.dialogoGuardarItem = false;
      this.botonDisabled = false;
      this.tituloDialogo = "Agregar";
      this.dialogoCambiarEstado = false;
      if (this.$refs.itemForm) {
        this.$refs.itemForm.reset();
      }
    }
  },
  async created() {
    this.userRoles = this.auth.roles;
    try{
      await this.listarItems();
    }catch(error){
      console.log(error)
    }
  },
  mounted(){

  }
}
</script>

<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
  font-weight: bold;
  color: black !important;
}
</style>